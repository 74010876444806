body {
  background-color: #283241;
}

.center {
  margin: 10px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 500px;
}

h1 {
  font-family:'Courier New', Courier, monospace;
  font-size: 800%;
  color:white;
}

h2 {
  margin: 10px;
  font-family:'Courier New', Courier, monospace;
  font-size: 110%;
  color:white;
}

.no-lr-padding {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
}

.item {
    height: calc(100vh - 77px);
}

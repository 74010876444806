.whisker {
    stroke: black;
    stroke-opacity: 0.75;
}

.bar, .bar-highlighted {
    stroke-width: 2px;
}

.bar {
    stroke-opacity: 0.8;
    fill-opacity: 0.5;
}

.bar-highlighted {
    stroke-opacity: 1;
    fill-opacity: 0.75;
}

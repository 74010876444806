.progress-root {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    overflow: hidden;

    /*
      Seems like a good idea to reset font-size because
      the SVG uses ems and might inherit a container's
      font-size
    */
    font-size: 1rem;
}

.progress-circle {
    transform: rotate(-90deg);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.progress-center {
    transition: all 0.5s;
    transition-delay: 1s;
    display: block;
    position: absolute;
    transform: translate3d(0, 0, 0);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.progress-value {
    box-sizing: border-box;
    word-wrap: break-word;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    width: 100%;
    height: 100%;
    line-height: 1;
}

.progress-shadow,
.progress-track,
.progress-meter {
    fill: none;
}

.progress-track {
    transition: all 0.2s;
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
    stroke: #F5F5F5;
}

.progress-meter {
    transition: stroke-dashoffset 1s;
    transform: translate3d(0, 0, 0);
}


.progress-shadow {
    stroke-width: 1.375em;
}


.progress-track,
.progress-meter {
    stroke-width: 0.875em;
}
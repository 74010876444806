.footer-columns {
    display: flex;
    flex-wrap: wrap;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    margin-top: 30px;
    padding-top: 20px;
    margin-bottom: 20px;
    border-top-color: lightgray;
    border-top-style: solid;
    border-top-width: 1px;
}

.footer-column {
    padding-left: 20px;
    padding-right: 20px;
}

.footer-column + .footer-column {
    border-left-color: lightgray;
    border-left-style: solid;
    border-left-width: 1px;
}

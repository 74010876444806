.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.navbar {
  border: none;
  background-color: #283241;
  height: 72px;
  margin-bottom: 0;
  padding: 10px;
}

.login {
  color: white;
}

.login:hover {
  color: #ed7733;
}

.welcome {
  color: white;
}

.logo-CAM-text {
  color: white;
}

.logo-i-text {
  color: #ed7733;
}

.logo-text {
  font-size: 50px;
  font-family: Arial;
  font-weight: bold;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
}

main > div {
  padding-top: 82px;
}

main > .welcome-div {
  padding-top: 72px
}

.navbar-right {
  margin-right: 0;
}

.main-container {
  min-height: 100vh;
}

.noTopBorder {
    border-top: none !important;
}

.caption {
    padding-top: 8px;
    padding-bottom: 8px;
    color: white;
    text-align: left;
    column-count: 2;
}

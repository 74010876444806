body {
  margin: 0;
  padding: 0;
  font-family: "arial" "sans-serif";
  font-size: 16px;
  max-width: 100%;
  overflow-x: hidden;
}

.centered-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.right-image {
  display: block;
  margin-left: auto;
  margin-right: 0;
}

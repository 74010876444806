body {
  margin: 0;
  padding: 0;
  font-family: "arial" "sans-serif";
  font-size: 16px;
  max-width: 100%;
  overflow-x: hidden;
}

.centered-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.right-image {
  display: block;
  margin-left: auto;
  margin-right: 0;
}

.progress-root {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    overflow: hidden;

    /*
      Seems like a good idea to reset font-size because
      the SVG uses ems and might inherit a container's
      font-size
    */
    font-size: 1rem;
}

.progress-circle {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.progress-center {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transition-delay: 1s;
            transition-delay: 1s;
    display: block;
    position: absolute;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.progress-value {
    box-sizing: border-box;
    word-wrap: break-word;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    width: 100%;
    height: 100%;
    line-height: 1;
}

.progress-shadow,
.progress-track,
.progress-meter {
    fill: none;
}

.progress-track {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
    stroke: #F5F5F5;
}

.progress-meter {
    -webkit-transition: stroke-dashoffset 1s;
    transition: stroke-dashoffset 1s;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}


.progress-shadow {
    stroke-width: 1.375em;
}


.progress-track,
.progress-meter {
    stroke-width: 0.875em;
}
.whisker {
    stroke: black;
    stroke-opacity: 0.75;
}

.bar, .bar-highlighted {
    stroke-width: 2px;
}

.bar {
    stroke-opacity: 0.8;
    fill-opacity: 0.5;
}

.bar-highlighted {
    stroke-opacity: 1;
    fill-opacity: 0.75;
}

.noTopBorder {
    border-top: none !important;
}

.caption {
    padding-top: 8px;
    padding-bottom: 8px;
    color: white;
    text-align: left;
    -webkit-column-count: 2;
            column-count: 2;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content p {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content p:hover {background-color: #ddd}

.show {display:block;}

.footer-columns {
    display: flex;
    flex-wrap: wrap;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    margin-top: 30px;
    padding-top: 20px;
    margin-bottom: 20px;
    border-top-color: lightgray;
    border-top-style: solid;
    border-top-width: 1px;
}

.footer-column {
    padding-left: 20px;
    padding-right: 20px;
}

.footer-column + .footer-column {
    border-left-color: lightgray;
    border-left-style: solid;
    border-left-width: 1px;
}

body {
  background-color: #283241;
}

.center {
  margin: 10px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 500px;
}

h1 {
  font-family:'Courier New', Courier, monospace;
  font-size: 800%;
  color:white;
}

h2 {
  margin: 10px;
  font-family:'Courier New', Courier, monospace;
  font-size: 110%;
  color:white;
}

.no-lr-padding {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
}

.item {
    height: calc(100vh - 77px);
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.navbar {
  border: none;
  background-color: #283241;
  height: 72px;
  margin-bottom: 0;
  padding: 10px;
}

.login {
  color: white;
}

.login:hover {
  color: #ed7733;
}

.welcome {
  color: white;
}

.logo-CAM-text {
  color: white;
}

.logo-i-text {
  color: #ed7733;
}

.logo-text {
  font-size: 50px;
  font-family: Arial;
  font-weight: bold;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
}

main > div {
  padding-top: 82px;
}

main > .welcome-div {
  padding-top: 72px
}

.navbar-right {
  margin-right: 0;
}

.main-container {
  min-height: 100vh;
}

